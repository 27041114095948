import React, { useState } from 'react';
import clsx from 'clsx';
import Content from '../components/Content';
import SideBar from '../components/SideBar';
import ProductAdd from '../components/ProductAdd';
import UserAdd from '../components/UserAdd';
import AllUsers from '../components/AllUsers';

const Users = () => {



    const [showSidebar, onSetShowSidebar] = useState(false);
    const toggleSidebar = () => {
        onSetShowSidebar(!showSidebar); // Toggle the current state
        // alert("menu clicked")
        
    };
    return (
        <div className="flex">
            <SideBar
                onSidebarHide={() => {
                    onSetShowSidebar(false);
                }}
                showSidebar={showSidebar}
            />
            <AllUsers
                onSidebarHide={() => {
                    onSetShowSidebar(false);
                }}
                showSidebar={showSidebar}
                    displaySidebar={toggleSidebar}
                    onSetShowSidebar={onSetShowSidebar}
            />
        </div>
    );

}

export default Users;
