import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const ViewPodructs = () => {
    const [productdata, setProductdata] = useState([]);

    const getProducts = async () => {
        try {
            const response = await axios.get('https://viatuback.vercel.app/api/v2/product/allproducts')
            const data = response.data;
            setProductdata(data)
            // console.log(response.data)
            // toast.success("data fetched");
            

        } catch (error) {
            console.log(error)
            toast.error("error getting products")

        }
    }

    useEffect(() => {
        getProducts()
    }, [])

    const navigate = useNavigate()


    const deleteProduct = async (id) => {
        const result = await Swal.fire({
          title: "Do you really want to delete?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, Delete it!",
        });
    
        if (result.isConfirmed) {
          try {
            await axios.delete(
              `https://viatuback.vercel.app/api/v2/product/deleteproduct/${id}`
            );
            toast.success("Product deleted");
            getProducts()
            navigate("/products");
    
          } catch (error) {
            toast.error(error.message);
          }
        }
      };
    return (
        <div>
            <div class="flex flex-col">
                <div class="-m-1.5 overflow-x-auto">
                    <div class="p-1.5 min-w-full inline-block align-middle">
                        <div class="overflow-hidden">
                        <div className="px-5 py-5"><h2 className="text-white">All Products</h2></div>
                            <table class="min-w-full divide-y divide-gray-200 dark:divide-neutral-700">
                                <thead>
                                    <tr>
                                        <th scope="col" class="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">ProductName</th>
                                        <th scope="col" class="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">Price</th>
                                        <th scope="col" class="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">InStock</th>
                                        <th scope="col" class="px-6 py-3 text-end text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">Action</th>
                                    </tr>
                                </thead>
                                <tbody class="divide-y divide-gray-200 dark:divide-neutral-700">
                                    {productdata.map((product) => {
                                        return (
                                            <tr class="hover:bg-gray-100 dark:hover:bg-neutral-700">
                                                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-neutral-200">{product.productname}</td>
                                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200">{product.price}</td>
                                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200">{product.inStock}</td>
                                                <td class="px-6 py-4 flex-row items-center justify-between space-x-3 whitespace-nowrap text-end text-sm font-medium">
                                                    <Link to={`/productedit/${product._id}`} class="inline-flex bg-green-500 p-2 items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-white hover:text-blue-800 disabled:opacity-50 ">Edit Product</Link>
                                                    <button onClick={()=>deleteProduct(product._id)} class="inline-flex bg-red-500 p-2 items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-white hover:text-blue-800 disabled:opacity-50 ">Delete</button>
                                                </td>
                                            </tr>
                                        )
                                    })}


                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewPodructs;
