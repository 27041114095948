import React, { useState } from 'react';
import SideBar from '../components/SideBar';
import ProductAdd from '../components/ProductAdd';

const AddProduct = () => {
    
        const [showSidebar, onSetShowSidebar] = useState(false);
        const toggleSidebar = () => {
            onSetShowSidebar(!showSidebar); // Toggle the current state
            alert("menu clicked")
            
        };
        return (
            <div className="flex">
                <SideBar
                    onSidebarHide={() => {
                        onSetShowSidebar(false);
                    }}
                    showSidebar={showSidebar}
                />
                <ProductAdd
                    onSidebarHide={() => {
                        onSetShowSidebar(false);
                    }}
                    showSidebar={showSidebar}
                    displaySidebar={toggleSidebar}
                    onSetShowSidebar={onSetShowSidebar}
                />
            </div>
        );
    
}

export default AddProduct;
